import { errorConstant } from "../constants/constants";

export const getErrorMsg = (type, value, value2) => {
  ////******************************VALIDATION RULES FOR  UNAUTH PAGES ***************************** */

  if (type == "email") {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%\\-+]+@[a-zA-Z0-9.-]+\\.{1}[a-zA-Z]+$"
    );
    return value == ""
      ? "Please enter email"
      : !validEmail.test(value) || value.length > 40
      ? "Invalid email address"
      : "";
  }
  if (type == "password") {
    //   const validPassword = new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,}$");
    const validPassword = new RegExp(
      "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,}$"
    );

    return value == ""
      ? "Please enter password"
      : value == value2
      ? "New password should not be same as old password"
      : !validPassword.test(value)
      ? "Invalid password format"
      : "";
  }
  if (type == "password_confirmation") {
    const validPassword = new RegExp(
      "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,}$"
    );
    return value == ""
      ? "Please enter confirm password"
      : value != value2
      ? " Confirm password is not matched "
      : !validPassword.test(value)
      ? "Invalid password format"
      : "";
  }
  if (type == "phone_number") {
    // return value == "" ? "Enter Phone Number" : isNaN(value) ? "Phone number must be in digits" : value.length != 10 ? "Phone number must be 10 digit" : ""
    return value == ""
      ? "Please enter mobile number"
      : isNaN(value)
      ? "Mobile number must be in digits"
      : value.length != 10
      ? "Mobile number must be 10 digit"
      : "";
  }
  if (type == "name" || type == "full_name" ) {
    const validName = new RegExp("^(([A-Za-z.]+) ?)+$");
    return value == "" || value == null
      ? "Please enter name"
      : !validName.test(value) || value?.trim().length < 2
      ? "Please enter valid name"
      : "";
  }

  if (type == "company_name") {
   // const validName = new RegExp("^(([A-Za-z0-9.]+) ?)+$");
   const validName = new RegExp("^([^\\s]+ ?)+$");
    return value == "" || value == null
      ? "Please enter your company name"
     // : !validName.test(value) || value?.trim().length < 2
     : value?.trim().length < 2
      ? "Please enter valid company name"
      : "";
  }

  
  if (type == "organisation_name") {
   // const validName = new RegExp("^(([A-Za-z0-9.]+) ?)+$");
  // const validName = new RegExp("^([^\\s]+ ?)+$");
    return value == "" || value == null
      ? "Please enter your company name"
      //: !validName.test(value) || value?.trim().length < 2
      : value?.trim().length < 2
      ? "Please enter valid company name"
      : "";
  }
  ////******************************VALIDATION RULES FOR AUTH PAGES ***************************** */

  if (type == "event_name" || type === 'project_name') {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null
      ? "Please enter project name"
      : value?.trim().length < 2
      ? "Please enter a valid project name"
      : "";
  }

  
  if (type == "team_name") {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null
      ? "Please enter team name"
      : value?.trim().length < 2
      ? "Please enter a valid team name"
      : "";
  }

  if (type == "team_members") {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null || value ==[]
      ? "Please select atleast one team member"
      : value?.length < 1
      ?  "Please select atleast one team member"
      : "";
  }

  if (type == "member") {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null
      ?  "Please select member name"
      : value?.length < 2
      ?  "Please select atleast one  member name"
      : "";
  }
  
  

  if (type == "song_type") {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null
      ? "Please enter song name"
      : value?.trim().length < 2
      ? "Please enter a valid song name"
      : "";
  }

  //  if (type === "start_date") {
  //     const temp = new Date(value*1000);
  //     console.log("validator", temp.getMonth());
  //     console.log("current mont", new Date().getMonth())
  //     return value == "" || value === null || value === NaN  ?
  //         "Please select start date" :
  //         value == "Invalid Date"? "Invalid date format" :
  //            ( temp.getMonth() < new Date().getMonth() ? true : temp.getMonth() == new Date().getMonth() ? temp.getDate() < new Date().getDate() : false) ? "Date should not be before current date" :
  //                 (temp.getFullYear() - 2) > new Date().getFullYear() ? "Date should not be after maximum date" : ""
  // }

  //   if (type === "start_date") {
  //     const temp = new Date(value*1000);
  //     return value == "" || value === null || value === NaN  ? "Please select start date" :
  //                 value == "Invalid Date"? "Invalid date format" :
  //                     (temp.getFullYear() == new Date().getFullYear() && temp.getMonth() == new Date().getMonth() && temp.getDate() < new Date().getDate()) ||
  //                          (temp.getFullYear() == new Date().getFullYear() && temp.getMonth() < new Date().getMonth()) ||
  //                              (temp.getFullYear() < new Date().getFullYear() ) ? "Date should not be before current date" :
  //                                  (temp.getFullYear() - 2) > new Date().getFullYear() ? "Date should not be after maximum date" : ""
  // }

  if (type == "start_date_time") {
    
    const temp = new Date(value * 1000);
    return value == "" || value == null || value == NaN
      ? "Please select start date and time"
      // : value == "Invalid Date"
      // ? "Invalid format"
      : (temp.getFullYear() == new Date().getFullYear() &&
          temp.getMonth() == new Date().getMonth() &&
          temp.getDate() < new Date().getDate()) ||
        (temp.getFullYear() == new Date().getFullYear() &&
          temp.getMonth() < new Date().getMonth()) ||
        temp.getFullYear() < new Date().getFullYear()
      ? "Date should not be before current date"
      : temp.getFullYear() - 2 > new Date().getFullYear()
      ? "Date should not be after maximum date"
      : "";
  }

  //     if (type == "end_date") {
  //         const temp = new Date(value*1000);
  //         const temp2 = new Date(value2*1000);
  //         return value == "" || value === null || value === NaN  ? "Please select end date" :
  //                     value == "Invalid Date"? "Invalid date format" :
  //                         (temp.getFullYear() == temp2.getFullYear() && temp.getMonth() == temp2.getMonth() && temp.getDate() < temp2.getDate()) ||
  //                              (temp.getFullYear() == temp2.getFullYear() && temp.getMonth() < temp2.getMonth()) ||
  //                                  (temp.getFullYear() < temp2.getFullYear() ) ? "Date should not be before start date" :
  //                                      (temp.getFullYear() - 2) > temp2.getFullYear() ? "Date should not be after maximum date" : ""
  //    }

  if (type == "end_date_time") {
  
    const temp = new Date(value * 1000);
    const temp2 = new Date(value2 * 1000);
    return value == "" || value === null || value === NaN
      ? "Please select end date"
      // : value == "Invalid Date"
      // ? "Invalid date format"
      : value < value2
      ? "Date should not be before start date"
      : value <= (value2 +3600)
      ? "Event must be atleast 1 hour long" 
      // : temp.getFullYear() - 2 > temp2.getFullYear()
      // ? "Date should not be after maximum date"
      : "";
  }

  // if (type == "editor_start_date_time") {
  //   const temp = new Date(value * 1000);
  //   const temp2 = new Date(value2 * 1000);
  //   return value == "" || value == null || value == NaN
  //     ? "Please select editing start date"
  //     // : value == "Invalid Date"
  //     // ? "Invalid date format"
  //     : value < value2
  //     ? "Date should not be before event start date"
  //     : value <= (value2 +1799)
  //     ? "Editing must start atleast 30 min after event start time" 
  
  //     // : temp.getFullYear() - 2 > temp2.getFullYear()
  //     // ? "Date should not be after maximum date"
  //     : "";
  // }

  if (type == "editor_start_date_time") {
    const temp = new Date(value * 1000);
    const temp2 = new Date(value2 * 1000);
    return value == "" || value == null || value == NaN
      ? "Please select editing start date"
      // : value == "Invalid Date"
      // ? "Invalid date format"
      :  (temp.getFullYear() == new Date().getFullYear() &&
      temp.getMonth() == new Date().getMonth() &&
      temp.getDate() < new Date().getDate()) ||
    (temp.getFullYear() == new Date().getFullYear() &&
      temp.getMonth() < new Date().getMonth()) ||
    temp.getFullYear() < new Date().getFullYear()
  ? "Date should not be before current date and time"
  : value < value2
      ? "Date should not be before current date and time"
      : value <= (value2 +1799)
      ? "Editing must start atleast 30 min after current time" 
  
      // : temp.getFullYear() - 2 > temp2.getFullYear()
      // ? "Date should not be after maximum date"
      : "";
  }

  if (type == "new_editor_start_date_time") {
    const temp = new Date(value * 1000);
    const temp2 = new Date(value2 * 1000);
    return value == "" || value == null || value == NaN
      ? "Please select new editing date"
      // : value == "Invalid Date"
      // ? "Invalid date format"
      : value < value2
      ? "Date and time should not be before previous editing date"
      : value <= (value2 +1799)
      ?  "Date should not be before previous editing date"
  
      // : temp.getFullYear() - 2 > temp2.getFullYear()
      // ? "Date should not be after maximum date"
      : "";
  }


  // if (type == "end_date") {
  //     const temp = new Date(value*1000);
  //     const temp2 = new Date(value2*1000);
  //     return value == "" || value == null || value == NaN ?
  //         "Please select end date" :
  //         value == "Invalid Date" ? "Invalid date format" :
  //             (temp.getDate() < temp2.getDate() && temp.getMonth() == temp2.getMonth()) || temp.getMonth() < temp2.getMonth() ? "Date should not be before start date" :
  //                 (temp.getFullYear() - 1) > temp2.getFullYear() ? "Date should not be after maximum date" : ""
  // }
  if (type == "genre") {
    return value == "" || value == null ? "Please select a genre" : "";
  }
  if (type == "phone_count_range") {
    return value == "" || value == null ? "Please select number of phones" : "";
  }
  if (type == "quality_option") {
    return value == "" || value == null ? "Please select capture quality" : "";
  }
  if (type == "event_type") {
    return value == "" || value == null ? "Please select event type" : "";
  }
  if (type == "video_length") {
    return value == "" || value == null ? "Please select video length" : "";
  }
  if (type == "role_type") {
    return value == "" || value == null ? "Please select role" : "";
  }
  if (type == "collab_code") {
    // const validName = new RegExp('^(([A-Za-z\.]+) ?)+$');
    return value == "" || value == null
      ? "Please enter invitation code"
      : value?.trim().length < 4
      ? "Invalid format"
      : "";
  }
};