

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, getContrastRatio,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState, updateState } from "../../../redux/commonSlice";
import { useNavigate } from "react-router-dom";
import "@fontsource/rubik";
import { resetAllMediaFilesArray } from "../../../redux/mediaFilesSlice";
import { resetReviewState } from "../../../redux/reviewSlice";
import { resetSnackbar } from "../../../redux/messageSlice";
import { resetCreationState } from "../../../redux/eventCreationSlice";
import useDeleteUser from "../../dashboard/hooks/useDeleteUser";
import { getInitials, logout } from "../../../utils/commonServices";
import usePermissions from "../../../hooks/usePermissions";
// import { customTheme as theme } from "../../../theme/customTheme";
import { setAssetState } from "../../../redux/assetSlice";
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateAppleSignState } from "../../../redux/appleSignSlice";

const useStyles = makeStyles((theme) => ({
  center: {
    //  display:'flex',
    //   alignItems: 'center',
    //   justifyContent:'center'
  },
  text: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold,
  
  },
}));

const CustomUserCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { avatarImg, userName, size = 'small' } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasPermission] = usePermissions();
  const [theme ] = useCustomTheme()

  const commonReducer = useSelector((state) => state.root.commonReducer);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const logout = () => {
  //   window.localStorage.removeItem("token");
  //   dispatch(resetAllMediaFilesArray())
  //   dispatch(resetReviewState())
  //   dispatch(resetSnackbar())
  //   dispatch(resetCreationState())
  //   dispatch(resetState());
  //   //   dispatch(updateState({
  //   //     is_logged: false,
  //   // }));
  //   //navigate('/signin');
  // };


 

  return (
    <Box
      {...props}
      className='flex row a-center j-center'
    //   sx={{
    //     display: { xs: "none", md: "flex" },
    //     alignItems: "center",
    //     justifyContent: "center",
      
    //   }}
      spacing={2}
    >

    <Box sx={{display:'flex', cursor:'pointer', justifyContent:'center', alignItems:'center'}}  onClick={commonReducer.is_logged == true && handleClick} gap={2}>

        { 
          size == "small" ?<Avatar sx={{width:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'}, height:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'},backgroundColor:theme.palette.primary.main, borderRadius:'8px', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase',  color:getContrastRatio( theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000'}}>{getInitials(commonReducer.user_name)}</Avatar>
        :  <Avatar sx={{width:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'}, height:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'},backgroundColor:theme.palette.primary.main, borderRadius:'8px', fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase',   color:getContrastRatio( theme.palette.primary.main , '#ffffff') > 2.5 ? '#ffffff' : '#000000'}}>{getInitials(commonReducer.user_name)}</Avatar>}
        <Typography
          className={classes.text}
          variant="body2"
          sx={{
            fontFamily: 'Rubik',
            fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'20px'},
            fontWeight: 600,
            color: 'black !important',
            textTransform:'capitalize'
          }}
        >
          {commonReducer.is_logged == false
            ? "Guest User"
            : commonReducer.user_name}
        </Typography>

        {/* <IconButton onClick={commonReducer.is_logged == true && handleClick}>
          <ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />
        </IconButton> */}

    </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        // //   vertical: "top",
        // //   horizontal: 133,
        // vertical:-8,
        // horizontal:87
        // }}
        transformOrigin={{
              vertical: -8,
              horizontal: 'right',
             }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        {/* <MenuItem
        
          style={{ height: 25 }}
          onClick={() => {
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
          My Profile
        </MenuItem> */}
        {/* <MenuItem
          style={{ height: 25 }}
          onClick={() => {
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
          My Subscriptions
        </MenuItem> */}
        <MenuItem
          // style={{ height: 25 }}
          onClick={() => {
            navigate('/myfiles', {replace:true})
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4, height: {xs:25, xl:32}, '&:hover':{backgroundColor:`${theme.palette.primary.main}70`}}}
        >
         My Files
        </MenuItem>

        { commonReducer?.is_admin &&  <MenuItem
          // style={{ height: 25 }}
          onClick={() => {
            if( commonReducer?.is_company_name_available === false){
              dispatch(setAssetState({brand_name_popup: true}))
            }else{ 
              dispatch(updateState({change_theme_popup:true}))
            }
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4, height: {xs:25, xl:32},  '&:hover':{backgroundColor:`${theme.palette.primary.main}70`}}}
        >
         White Label Assets
        </MenuItem>
        }

       
      { commonReducer?.is_admin && <MenuItem
          style={{ height: 25 }}
          onClick={() => {
            if( commonReducer?.is_company_name_available === false){
              // if( commonReducer?.company_name === null || commonReducer?.company_name === ''){
              dispatch(setAssetState({brand_name_popup: true, brand_box_clicked: true}))
            }else{
              navigate('/brandbox')
            }
            handleClose();
          }}
          sx={{'&:hover':{backgroundColor:`${theme.palette.primary.main}70`},color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
         My Brandbox
        </MenuItem>}

        {/* <MenuItem
          style={{ height: 25 }}
          onClick={() => {
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
         Privacy Policy
        </MenuItem> */}

        <MenuItem
          // style={{ height: 25 ,}}
          onClick={() => {
            dispatch(updateState({account_delete_popup : true}))
            setAnchorEl(null)
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4, height:{xs:25, xl:32},  '&:hover':{backgroundColor:`${theme.palette.primary.main}70`}}}
        >
          Delete Account
        </MenuItem>

        <MenuItem
          // style={{ height: 25 ,}}
          onClick={() => {
            logout(dispatch);
             window.localStorage.setItem('logout_all', "true")
             // dispatch(updateState({logout_all: true}))
              window.location.href=`${process.env.REACT_APP_COPILOT_URL}/redirect?request=logout`;
              handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4, height:{xs:25, xl:32},  '&:hover':{backgroundColor:`${theme.palette.primary.main}70`}}}
        >
          {/* <ListItemIcon>
            <LogoutIcon fontSize="small" color="primary" />
          </ListItemIcon> */}
          {/* <ListItemText sx={{fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}} color="primary">Log out</ListItemText> */}
          Log out
        </MenuItem>
       
      </Menu>
    </Box>
  );
};

export default CustomUserCard;